import { add } from "date-fns";

export default {
	hoteldetails: "Détails de l'hôtel",
	hotelincluded: "Hôtel inclus",
	expand: "Développer",
	adults: "adulte | adultes",
	childs: "enfant | enfants",
	selectedrooms: "Chambre sélectionnée | Chambres sélectionnées",
	paymentsummary: "Résumé du paiement",
	advancepayment: "Acompte",
	balance: "Solde",
	paymentoptions: "Options de paiement",
	onsitecosts: "Frais sur place",
	creditcard: "Carte de crédit",
	banktransfer: "Virement bancaire",
	scalapaydetails: "Payer en 3 fois sans frais",
	cannotconfirm:
		"Vous ne pouvez pas encore payer pour confirmer cette réservation. Attendez notre communication pour accéder.",
	nofilefound: "Aucun fichier trouvé!",
	personaldata: "Données personnelles",
	stay: "Séjour",
	flights: "Vols",
	ships: "Navires",
	transfer: "Transfert",
	insurance: "Assurance",
	cancellations: "Annulations",
	payments: "Paiements",
	filenumber: "Numéro de fichier",
	requestsummary: "Résumé de la demande",
	seeall: "Voir tout",
	thankyoumsg: "Nous vous remercions d'avoir partagé votre avis!",
	sendreview: "Envoyez votre avis",
	reservationstatus: "Statut de la réservation",
	openingperiod: "Période d'ouverture",
	dal: "Du",
	al: "Au",
	toproceedmsg:
		"Pour procéder au paiement et à la confirmation de la réservation, veuillez saisir vos données de facturation et donner votre consentement à l'utilisation.",
	wannaconfirm1: "Voulez-vous confirmer encore?",
	wannaconfirm2: "Demandez une mise à jour de l'offre.",
	bookingcancelled:
		"Votre réservation a été ANNULÉE. Pour plus d'informations, veuillez contacter",
	emailsubject: "Demande de mise à jour de l'offre n°",
	youhavestill: "vous avez encore",
	toconfirm: "pour confirmer cette offre",
	clickhere: "cliquez ici",
	updateproposal: "Mettez à jour votre proposition",
	downloaddocs: "Téléchargez les documents de voyage",
	welcome: "Bienvenue",
	room: "Chambre",
	for: "pour",
	age: "âge",
	treatment: "Traitement",
	roomdescription: "Description de la chambre",
	structureextras: "Extras de structure",
	gratis: "gratuit",
	basecost: "Coût de base",
	discountalreadyapplied: "Remises déjà appliquées",
	getmore: "Get More",
	extra: "Frais supplémentaires à payer au moment de la réservation",
	roomnetcost: "Coût net de la chambre",
	reviews: "avis",
	arrival: "Arrivée",
	leaving: "Départ",
	nights: "Nuits",
	rooms: "Chambres",
	persons: "Personnes",
	onsitecostsexcluded: "hors frais éventuels sur place",
	onsitecoststotal: "Total des frais sur place",
	go: "ALLER",
	return: "RETOUR",
	route: "Itinéraire",
	passengers: "Passagers",
	accomodation: "Hébergement",
	transportation: "Transport",
	plate: "Plaque",
	company: "Entreprise",
	transferroute: "Itinéraire de transfert",
	vehicle: "Véhicule",
	flight: "Vol",
	otherdiscounts: "Autres réductions",
	packageextras: "Extras de forfait",
	axa: "Assurance AXA 360°",
	cancellationinsurance: "Annulation, Frais médicaux, Bagages",
	insuredpersons: "Personnes assurées",
	readconditions: "Lire les conditions d'assurance",
	packages: "Forfaits",
	companydata: "Données de l'entreprise",
	custmerdata: "Données personnelles",
	travelerdata: "Données du voyageur",
	passengersdata: "Données des passagers",
	passenger: "Passager",
	privacyconfirmation:
		"Je confirme avoir lu et accepté la politique de confidentialité et les cookies.",
	newsletterconfirmation:
		"Je souhaite recevoir des informations et des offres par e-mail de Charming.",
	updatedata: "Mettez à jour vos données",
	customernotes: "NOTES CLIENT",
	structurenotes: "NOTES (Structure pour le client)",
	bookingnotes: "NOTES (Booking pour le client)",
	vouchernotes: "NOTES DE BON",
	packagenotes: "NOTES DE FORFAIT",
	travelinsurance: "Assurance voyage",
	details: "détails",
	ship: "Navire",
	costssummary: "Résumé des coûts et des paiements",
	totalpract: "Total de la pratique",
	holidaystart: "Début des vacances",
	holidaystop: "Fin des vacances",
	location: "Emplacement",
	printfile: "Imprimez votre fichier",
	rating: "Évaluation",
	services: "Services",
	cleaning: "Nettoyage",
	staff: "Personnel",
	comfort: "Confort",
	qualityprice: "Rapport qualité/prix",
	catering: "Restauration",
	ratestructure: "Évaluez la structure",
	leavecommentstructure: "Laissez un commentaire sur la structure",
	positivecomment: "Commentaire positif",
	negativecomment: "Commentaire négatif",
	rateourservice: "Évaluez notre service",
	howwasreservewithus: "Comment s'est passé la réservation avec nous?",
	leavecomment: "Laisser un commentaire",
	ratelocation: "Évaluez l'emplacement",
	none: "Aucun",
	youngcouples: "Jeunes couples",
	maturecouples: "Couples matures",
	familywithyoungsons: "Famille avec de jeunes fils",
	familywithbigsons: "Famille avec de grands fils",
	withfriends: "Avec des amis",
	singletravellers: "Voyageurs seuls",
	groupstravel: "Voyage en groupe",
	save: "Économisez {savedAmount}!",
	costofyourholiday:
		"Le coût de vos vacances est de {totalAmount} au lieu de {undiscountedAmount}",
	writelike: "Écrivez ce que vous avez aimé",
	writedislike: "Écrivez ce que vous n'avez pas aimé",
	startingfrom: "à partir de",
	pho: "Tél.",
	atglance: "En un coup d'œil",
	charmingopinion: "Avis Charming",
	days: "Jours",
	hours: "Heures",
	minutes: "Minutes",
	seconds: "Secondes",
	paycreditcard: "Payer par carte de crédit",
	paythreetrances: "Payer en trois tranches à partir de {amount}",
	paybanktransfer: "Payer par virement bancaire",
	pay: "Payer",
	howtodeposit:
		"Comment effectuer un dépôt par virement bancaire ou mandat postal",
	close: "Fermer",
	voucher: "Bon",
	novoucheravailable:
		"Le document de voyage pour ce service n'est pas encore disponible.",
	yes: "Oui",
	no: "Non",
	toTop: "Haut de page",
	topayuntil: "À payer jusqu'au",
	paidon: "Payé le",
	costtransf: "Coût du transfert",
	costtottransfdiscounts: "Remise appliquée",
	costtottransf: "Coût total du transfert",
	at: "à",
	validations: {
		required: "Ce champ est requis.",
		maxLength: "Ce champ doit comporter au maximum {max} caractères.",
		numeric: "Ce champ doit être un nombre.",
		email: "Ce champ doit être une adresse e-mail valide.",
		taxCode: "Ce champ doit être un code fiscal valide."
	},
	roomcost: "COÛT DE LA CHAMBRE",
	requiredfield: "Champ obligatoire",
	arrivaldate: "Date d'arrivée",
	departuredate: "Date de départ",
	adultslabel: "Adultes",
	childslabel: "Enfants",
	regions: "Régions",
	cities: "Villes",
	noresults: "Aucun résultat",
	search: "Rechercher",
	edit: "Modifier",
	experiencesfound: "expériences trouvées",
	experiences: "Expériences",
	addExperience: "Ajouter une expérience",
	discoveractivities:
		"Découvrez les activités sélectionnées pour vous par notre équipe.",
	filters: "Filtres",
	appliedfilters: "Filtres appliqués",
	closefilters: "Fermer les filtres",
	filter: "Filtrer",
	changesearch: "Modifier la recherche",
	showmoreexp: "Afficher plus d'expériences",
	showmoretravel: "Afficher plus de voyages intelligents",
	showtravel: "Afficher les voyages",
	update: "mise à jour",
	chooseyourinterests: "Choisissez vos centres d'intérêt",
	applyfilters: "Appliquer les filtres",
	language: "Langue",
	whatdestination: "Quelle destination souhaitez-vous visiter?",
	whentravel: "Quand partiras-tu en vacances?",
	withtravel: "Avec qui partirez-vous?",
	whatkindoftraveler: "Quel type de voyageur êtes-vous?",
	duration: "Durée",
	perperson: "Par personne",
	howumuchtospend: "Combien souhaitez-vous dépenser?",
	uniquejourney: "Proposé pour vous par des experts locaux",
	clear: "Effacer",
	allitaly: "Toute l'Italie",
	experiencescart: "Panier d'expériences",
	selectyourinterests: "Sélectionnez vos centres d'intérêt",
	thankspurchase: "Merci pour votre achat",
	emailwithrecap:
		"Vous recevrez bientôt un e-mail de confirmation avec un résumé des expériences achetées. Bonne chance!",
	continuenavigation: "Continuer la navigation sur le site",
	thanksforusing: "Merci d'utiliser",
	highlights: "Points forts",
	description: "Description",
	importantinformation: "Informations importantes",
	includesnotincludes: "Comprend/Ne comprend pas",
	meetingpoint: "Point de rencontre",
	cancellationpolicy: "Politique d'annulation",
	temesofthisexperience: "Les thèmes de cette expérience",
	bookingdata: "DONNÉES DE RÉSERVATION",
	verifytheavailability: "Vérifier la disponibilité",
	selecttravel: "Choisissez ce voyage",
	dataaddon: "Données supplémentaires",
	weneedmoreinformation: "Pour réserver cette expérience, nous avons besoin de quelques informations supplémentaires.",
	choosethetipology: "Choisissez la typologie",
	bringyourjourneytolife: "Donnez vie à votre voyage",
	theteamof: "L'équipe de",
	bestoriceguaranteed: "Meilleur prix garanti",
	advicefromlocalexperts: "Conseils d'experts locaux",
	assistance77: "Assistance 7 jours sur 7",
	safepayment: "Paiement sécurisé",
	ticketandextra: "Billet et Extra",
	ticket: "billet",
	selectTicket: "Sélectionnez le nombre de billets",
	remove: "retirer",
	experiencescart: "Panier d'expériences",
	total: "Total",
	confirm: "Confirmer",
	voucheraccepted: "Bon accepté sur mobile",
	immediateconfirmation: "Confirmation immédiate",
	freecancellation: "Annulation gratuite",
	upto24hours: "Jusqu'à 24 heures avant",
	continuepurchase: "Continuer les achats",
	noexperiencesoncart: "Pas encore d'expériences dans le panier",
	warning: "Avertissement",
	youhave: "Vous avez",
	minutestoendthebooking:
		"minutes pour terminer la réservation. Assurez-vous d'avoir toutes les informations nécessaires.",
	timeended: "Temps écoulé!",
	verifyifjourneyavailable: "Vérifiez si le voyage est toujours disponible.",
	travelerdata: "Données du voyageur",
	discountcode: "Code de réduction",
	optional: "Facultatif",
	insertdiscountcode: "Insérer le code de réduction",
	apply: "Appliquer",
	generalconditionsandprivacy: "Conditions générales et confidentialité",
	generalconditionsandcancellation:
		"Conditions générales et politiques d'annulation",
	privacypolicy: "Politique de confidentialité",
	within: "dans",
	atthetimeofconfirmation: "au moment de la confirmation",
	infoonpayment: "Informations de paiement",
	deposittobepaid: "Acompte à payer",
	balancetobepaidby: "Solde à payer avant",
	bookwithbanktransfer: "Réserver par virement bancaire",
	bookwithcreditcard: "Réservez maintenant avec une carte de crédit",
	paytheadvancepayment: "Payer l'acompte",
	withoutinterest: "sans intérêt",
	errorinbooking: "Erreur de réservation",
	choodethemethod: "Choisissez la méthode de paiement",
	bookandpay: "Réserver et payer",
	booktravel: "Réservez le voyage",
	bookwithoutpayment: "Réserver sans paiement",
	experiencesrecap: "Récapitulatif des expériences",
	insertrequestinfo:
		"Saisissez les données requises pour procéder à la réservation.",
	acceptgeneralconditions: "Accepter les conditions générales",
	confirmbooking: "Confirmer la réservation",
	experiencesnotfound: "Aucune expérience trouvée",
	filtersearch: "Filtrez votre recherche",
	searchadestination: "Rechercher une destination",
	noresults: "Aucun résultat",
	readless: "Lire moins",
	sharewithfriends: "Partagez cette expérience avec vos amis et votre famille",
	languages: "Langues",
	selectLanguage: "Sélectionnez la langue",
	groupsize: "Taille du groupe",
	immediateconfirmation: "Confirmation immédiate",
	animalsaccepted: "Animaux acceptés",
	experienceforanyone: "Expérience pour tout le monde",
	cancellationterms: "Conditions d'annulation",
	fullpricefrom: "Prix plein à partir de",
	reducedby: "Réduit de",
	share: "Partager",
	discovermore: "Découvrir plus",
	discovermorehotel: "En savoir plus sur l'hôtel",
	discoverlesshotel: "Fermer les informations sur l'hôtel",
	searchexperiences: "Rechercher des expériences",
	option: "Option",
	from: "De",
	to: "à",
	locality: "Localité",
	viewonthemap: "Voir sur la carte",
	view: "Vois",
	adult: "Adulte",
	children: "Enfants",
	child_age: "Âge de l'enfant",
	close: "Fermer",
	travelers: "Voyageurs",
	adults: "Adultes",
	and: "et",
	child: "Enfant",
	year: "An",
	years: "Ans",
	of: "de",
	chooseyourjourney: "Choisissez votre voyage",
	arrivaldate: "Date d'arrivée",
	whendoyouwanttoleave: "Quand voulez-vous partir?",
	searchexperiencesorlocality: "Rechercher des expériences ou une localité",
	experiencesnotavailable:
		"Il n’y a aucune expérience disponible dans l’emplacement demandé. Découvrez-les à quelques kilomètres de",
	accommodationsNotAvailable: "Il n’y a pas d’hébergement disponible à l’endroit demandé. Jetez un oeil à ceux-ci, à seulement quelques kilomètres",
	smartjourneysnotfound:
		"Désolé, nous n'avons pas encore de voyages correspondant à votre recherche.",
	filterbylocalityorjourneyname: "Filtrer par lieu ou nom de voyage",
	viaggismartfound: "voyages intelligents trouvés",
	smarttrips: "voyage intelligent",
	travelsolutions:
		"Des propositions de voyage complètes créées pour vous par nos experts locaux.",
	personalizationavailable:
		"Vous pouvez les personnaliser en choisissant l'hébergement et les expériences que vous préférez.",
	travelduration: "Durée du voyage",
	durationnights: "Durée (nuits)",
	night: "nuit",
	reset: "Réinitialiser",
	refund: "Rimborso",
	experiencesincluded: "Expériences incluses",
	duration: "Durée",
	from: "De",
	days: "Jours",
	day: "Jour",
	hours: "Heures",
	hour: "Heure",
	minutes: "Minutes",
	minute: "Minute",
	thethemes: "Les thèmes",
	nottobemissed: "À ne pas manquer",
	poi: "Points d'intérêt",
	months: "Genuaire,février,mars,avril,mai,juin,juillet,août,septembre,octobre,novembre,décembre",
	localexpertsopinion: "Avis d'experts locaux",
	tovisitifyouhavetime: "À visiter si vous avez le temps",
	itdeservesavisit: "Ça vaut le détour",
	donotmiss: "Ne manquez pas",
	week: "Semaine",
	who: "Qui",
	when: "Quand",
	travelersdata: "Données de tous les voyageurs",
	inserttravelersdata: "Insérez les données de tous les voyageurs",
	traveler: "Voyageur",
	add: "Ajouter",
	date: "Date",
	experience: "Expérience",
	seeaccomodation: "",
	changehotel: "Changer d'hôtel",
	select: "Sélectionner",
	generalconditions: "Conditions générales",
	cancellationpolicy: "Politique d'annulation",
	changehotel: "Changer d'hôtel",
	therearenoexperiencesinserted: "Il n'y a pas d'expériences insérées",
	chooseRoom: "Choisissez la chambre",
	timetable: "Heures",
	chooseTime: "Choisir une heure",
	chooseLanguage: "Choisissez votre langue",
	ticketType: "Type de billet",
	loading: "Nous récupérons les données pour vous...",
	AddContinue: "Ajouter et continuer",
	allOfItaly: "Toute l'Italie",
	accommodationNotIncluded: "L'expérience sélectionnée n'est pas disponible aux dates sélectionnées.",
	chooseAnotherAccommodation: "Modifier la date ou ajouter une autre expérience",
	nocancellationterms: "Aucune condition d'annulation",
	experienceIsNotAvailable: "L'expérience n'est pas disponible pour les dates sélectionnées.",
	addAnotherExperience: "Ajouter une autre expérience",
	itinerarySummary: "Résumé de l'itinéraire",
	toBePaidImmediately: "A payer immédiatement",
	toBePaidBy: "A payer par",
	enterDetails: "Entrez vos coordonnées",
	editDetails: "Modifiez vos données",
	thecodeinsertedcouldnotbeapplied: "Le code inséré ne peut pas être appliqué",
	verifythecode: "Vérifiez le code",
	discountcodecorrectlyappliedpriceupdated: "Code de réduction correctement appliqué. Prix mis à jour",
	thediscountcodehasbeenappliedcorrectly: "Le code de réduction a été appliqué correctement",
	chooseRoom: "Choisissez votre chambre",
	priceRoom: "Prix par chambre et par séjour",
	terms: "conditions",
	oneofthechosenproductsisnologneravailable: "Un des produits choisis n'est plus disponible. Veuillez vérifier votre itinéraire.",
	chooseTravel: "Choisissez votre voyage",
	personalize: "Personnalisez votre voyage",
};
