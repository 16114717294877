import { ref } from "vue";
import { useParamsStore } from "@/stores/commons/paramsStore";
import { useI18n } from "vue-i18n";
import { Options, Params } from "@/types/params";

export function useWcParams() {
	const wcParams = ref<Params>({
		lang: "",
		display: "",
		type: ""
	});
	const setWcParams = (props: {
		lang: string;
		display: string;
		type?: string;
		params?: string | Options;
	}) => {
		let {lang, type, display, params: propParams, ...otherProps} = props ?? {};
		const queryString = window.location.search;
		let urlParams = new URLSearchParams(queryString);
		const language = urlParams.get("l") || lang;
		const typeId = urlParams.get("t") || type;
		const displayId = urlParams.get("d") || display;
		const qsParams = urlParams.get("p");
		propParams = propParams && typeof propParams === "string" ? JSON.parse(propParams) : (propParams ?? {});
		//unisce i parametri passati come props al web-component con quelli passati come query string dando priorità a questi ultimi
		const options: Options = {
			...propParams as Options,
			...(qsParams ? JSON.parse(qsParams) : {})
		}

		const paramsStore = useParamsStore();
		wcParams.value = {
			...otherProps,
			lang: language,
			display: displayId,
			type: typeId,
			options: options,
		};

		paramsStore.setParams(wcParams.value);

		const { locale } = useI18n({ useScope: "global" });
		locale.value = language;

		const theme = options?.theme ? options?.theme : "default";
		document.querySelector("html")?.setAttribute("data-theme", theme);
	};

	return {
		wcParams,
		setWcParams,
	};
}
