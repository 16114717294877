<script setup>
import { defineAsyncComponent } from "vue";
import { useParamsStore } from "@/stores/commons/paramsStore";
import { storeToRefs } from "pinia";

const Hotels = defineAsyncComponent(() => import("@/components/Forms/Hotels.vue")),
  Experience = defineAsyncComponent(() => import("@/components/Forms/Experience.vue")),
  Viaggismart = defineAsyncComponent(() => import("@/components/Forms/Viaggismart.vue"));

const paramsStore = useParamsStore();
const { params } = storeToRefs(paramsStore);
</script>
<template>
  <div>
    <Hotels v-if="params.type === 'hotels'" />
    <Experience v-if="params.type === 'experience'" />
    <Viaggismart v-if="params.type === 'viaggismart'" />
  </div>
</template>
