import { add } from "date-fns";

export default {
	hoteldetails: "Hotel Details",
	hotelincluded: "Hotel inklusive",
	expand: "Erweitern",
	adults: "Erwachsener | Erwachsene",
	childs: "Kind | Kinder",
	selectedrooms: "Ausgewähltes Zimmer | Ausgewählte Zimmer",
	paymentsummary: "Zahlungszusammenfassung",
	advancepayment: "Anzahlung",
	balance: "Guthaben",
	paymentoptions: "Zahlungsmöglichkeiten",
	onsitecosts: "Kosten vor Ort zu zahlen",
	creditcard: "Kreditkarte",
	banktransfer: "Banküberweisung",
	scalapaydetails: "Zahlen Sie in 3 Zinsenfreien Raten",
	cannotconfirm:
		"Sie können diese Buchung noch nicht bezahlen, um sie zu bestätigen. Bitte warten Sie auf unsere Mitteilung, um fortzufahren.",
	nofilefound: "Keine Datei gefunden!",
	personaldata: "Persönliche Daten",
	stay: "Unterkunft",
	flights: "Flüge",
	ships: "Schiffe",
	transfer: "Transfer",
	insurance: "Versicherung",
	cancellations: "Stornierungen",
	payments: "Zahlungen",
	filenumber: "Aktennummer",
	requestsummary: "Anforderungszusammenfassung",
	seeall: "Alles anzeigen",
	thankyoumsg: "Vielen Dank für das Teilen Ihrer Meinung!",
	sendreview: "Ihre Bewertung senden",
	reservationstatus: "Reservierungsstatus",
	openingperiod: "Eröffnungszeitraum",
	dal: "Von",
	al: "An",
	toproceedmsg:
		"Um mit der Zahlung und der Buchungsbestätigung fortzufahren, geben Sie bitte Ihre Rechnungsdetails ein und geben Sie Ihre Zustimmung zur Nutzung.",
	wannaconfirm1: "Möchten Sie noch bestätigen?",
	wannaconfirm2: "Fordern Sie eine Aktualisierung des Angebots an.",
	bookingcancelled:
		"Ihre Buchung wurde STORNIERT. Für weitere Informationen kontaktieren Sie bitte",
	emailsubject: "Angebot Aktualisierungsanfrage Nr.",
	youhavestill: "Sie haben immer noch",
	toconfirm: "um dieses Angebot zu bestätigen",
	clickhere: "hier klicken",
	updateproposal: "Aktualisieren Sie Ihren Vorschlag",
	downloaddocs: "Reisedokumente herunterladen",
	welcome: "Willkommen",
	room: "Zimmer",
	for: "für",
	age: "Alter",
	treatment: "Behandlung",
	roomdescription: "Zimmerbeschreibung",
	structureextras: "Zusätzliche Struktur",
	gratis: "gratis",
	basecost: "Grundkosten",
	discountalreadyapplied: "Bereits angewendete Rabatte",
	getmore: "Get More",
	extra: "Zusätzliche Kosten zum Zeitpunkt der Buchung",
	roomnetcost: "Nettokosten des Zimmers",
	reviews: "Bewertungen",
	arrival: "Ankunft",
	leaving: "Abfahrt",
	nights: "Nächte",
	rooms: "Zimmer",
	persons: "Personen",
	onsitecostsexcluded: "ohne mögliche Vor-Ort-Kosten",
	onsitecoststotal: "Gesamtkosten vor Ort",
	go: "ABFAHRT",
	return: "RÜCKKEHR",
	route: "Route",
	passengers: "Passagiere",
	accommodation: "Unterkunft",
	transportation: "Transport",
	plate: "Platte",
	company: "Unternehmen",
	transferroute: "Transferstrecke",
	vehicle: "Fahrzeug",
	flight: "Flug",
	otherdiscounts: "Andere Rabatte",
	packageextras: "Paketextras",
	axa: "AXA 360° Police",
	cancellationinsurance: "Stornierung, Krankheitskosten, Gepäck",
	insuredpersons: "Versicherte Personen",
	readconditions: "Versicherungsbedingungen anzeigen",
	packages: "Pakete",
	companydata: "Firmendaten",
	custmerdata: "Persönliche Daten",
	travelerdata: "Reisendendaten",
	passengersdata: "Passagierdaten",
	passenger: "Passagier",
	privacyconfirmation:
		"Ich bestätige, dass ich die Datenschutz- und Cookie-Richtlinien gelesen und akzeptiert habe.",
	newsletterconfirmation:
		"Ich möchte Informationen und Angebote per E-Mail von Charming erhalten.",
	updatedata: "Aktualisieren Sie Ihre Daten",
	customernotes: "KUNDENHINWEISE",
	structurenotes: "HINWEISE (Struktur für Kunden)",
	bookingnotes: "HINWEISE (Buchung für Kunden)",
	vouchernotes: "VOUCHER-HINWEISE",
	packagenotes: "PAKETHINWEISE",
	travelinsurance: "Reiseversicherung",
	details: "Details",
	ship: "Schiff",
	costssummary: "Kosten- und Zahlungszusammenfassung",
	totalpract: "Gesamtdatei",
	holidaystart: "Ferienbeginn",
	holidaystop: "Ferienende",
	location: "Ort",
	printfile: "Drucken Sie Ihre Datei",
	rating: "Bewertung",
	services: "Dienstleistungen",
	cleaning: "Reinigung",
	staff: "Personal",
	comfort: "Komfort",
	qualityprice: "Qualitäts-Preis-Verhältnis",
	catering: "Verpflegung",
	ratestructure: "Bewerten Sie die Struktur",
	leavecommentstructure: "Hinterlassen Sie eine Bewertung der Struktur",
	positivecomment: "Positive Bewertung",
	negativecomment: "Negative Bewertung",
	rateourservice: "Bewerten Sie unseren Service",
	howwasreservewithus: "Wie war die Buchung bei uns?",
	leavecomment: "Einen Kommentar hinterlassen",
	ratelocation: "Bewerten Sie den Standort",
	none: "Keine",
	youngcouples: "Junge Paare",
	maturecouples: "Reife Paare",
	familywithyoungsons: "Familie mit kleinen Söhnen",
	familywithbigsons: "Familie mit großen Söhnen",
	withfriends: "Mit Freunden",
	singletravellers: "Alleinreisende",
	groupstravel: "Gruppenreise",
	save: "Speichern {savedAmount}!",
	costofyourholiday:
		"Die Kosten Ihres Urlaubs betragen {totalAmount} anstelle von {undiscountedAmount}",
	writelike: "Schreiben Sie, was Ihnen gefallen hat",
	writedislike: "Schreiben Sie",
	startingfrom: "ab",
	pho: "Tel.",
	atglance: "Auf einen Blick",
	charmingopinion: "Charming Meinung",
	days: "Tage",
	hours: "Stunden",
	minutes: "Minuten",
	seconds: "Sekunden",
	paycreditcard: "Mit Kreditkarte bezahlen",
	paythreetrances: "Zahlen Sie in drei Raten ab {amount}",
	paybanktransfer: "Mit Banküberweisung bezahlen",
	pay: "Bezahlen",
	howtodeposit:
		"So tätigen Sie eine Einzahlung per Banküberweisung oder Postscheck",
	close: "Schließen",
	voucher: "Gutschein",
	novoucheravailable:
		"Das Reisedokument für diesen Service ist noch nicht verfügbar.",
	yes: "Ja",
	no: "Nein",
	toTop: "Zurück nach oben",
	topayuntil: "Zu bezahlen bis",
	paidon: "Bezahlt am",
	costtransf: "Transferkosten",
	costtottransfdiscounts: "Angewendeter Rabatt",
	costtottransf: "Gesamtkosten für den Transfer",
	at: "um",
	validations: {
		required: "Dieses Feld ist erforderlich.",
		maxLength: "Das Feld darf maximal {max} Zeichen lang sein.",
		numeric: "Das Feld muss eine Zahl sein.",
		email: "Das Feld muss eine gültige E-Mail-Adresse sein.",
		taxCode: "Das Feld muss eine gültige Steuernummer sein."
	},
	roomcost: "ZIMMERKOSTEN",
	requiredfield: "Erforderliches Feld",
	arrivaldate: "Ankunftsdatum",
	departuredate: "Abreisedatum",
	adultslabel: "Erwachsene",
	childslabel: "Kinder",
	regions: "Regionen",
	cities: "Städte",
	noresults: "Keine Ergebnisse",
	search: "Suche",
	edit: "Bearbeiten",
	experiencesfound: "gefundene Erfahrungen",
	experiences: "Erfahrungen",
	addExperience: "Erfahrung hinzufügen",
	discoveractivities:
		"Entdecken Sie die für Sie ausgewählten Aktivitäten unseres Teams.",
	filters: "Filter",
	appliedfilters: "Angewendete Filter",
	closefilters: "Filter schließen",
	filter: "Filter",
	changesearch: "Suche ändern",
	showmoreexp: "Weitere Erfahrungen anzeigen",
	showmoretravel: "Weitere intelligente Reisen anzeigen",
	showtravel: "Showfahrten",
	update: "Aktualisieren",
	chooseyourinterests: "Wählen Sie Ihre Interessen",
	applyfilters: "Filter anwenden",
	language: "Sprache",
	selectLanguage: "Sprache auswählen",
	whatdestination: "Welches Ziel möchten Sie besuchen?",
	whentravel: "Wann fahren Sie in den Urlaub?",
	withtravel: "Mit wem wirst du gehen?",
	whatkindoftraveler: "Was für ein Reisender bist du?",
	duration: "Dauer",
	perperson: "Pro Person",
	howumuchtospend: "Wie viel möchten Sie ausgeben?",
	uniquejourney: "Machen Sie Ihre Reise einzigartig",
	clear: "Löschen",
	allitaly: "Ganz Italien",
	experiencescart: "Erfahrungen Warenkorb",
	selectyourinterests: "Wählen Sie Ihre Interessen",
	thankspurchase: "Vielen Dank für Ihren Kauf",
	emailwithrecap:
		"Sie erhalten in Kürze eine Bestätigungs-E-Mail mit einer Zusammenfassung der gekauften Erfahrungen. Viel Spaß!",
	continuenavigation: "Weiter zur Navigation auf der Website",
	thanksforusing: "Danke für die Benutzung",
	highlights: "Highlights",
	description: "Beschreibung",
	importantinformation: "Wichtige Informationen",
	includesnotincludes: "Enthält/Enthält nicht",
	meetingpoint: "Treffpunkt",
	cancellationpolicy: "Stornierungsbedingungen",
	temesofthisexperience: "Die Themen dieser Erfahrung",
	bookingdata: "DATEN FÜR DIE BUCHUNG",
	verifytheavailability: "Verfügbarkeit überprüfen",
	selecttravel: "Wählen Sie diese Reise",
	dataaddon: "Zusätzliche Daten",
	weneedmoreinformation: "Um dieses Erlebnis zu buchen, benötigen wir einige zusätzliche Informationen.",
	choosethetipology: "Wählen Sie die Typologie",
	bringyourjourneytolife: "Bringen Sie Ihre Reise zum Leben",
	theteamof: "Das Team von",
	bestoriceguaranteed: "Bester Preis garantiert",
	advicefromlocalexperts: "Beratung von lokalen Experten",
	assistance77: "Hilfe 7 Tage die Woche",
	safepayment: "Sichere Zahlung",
	ticketandextra: "Ticket und Extra",
	ticket: "Ticket",
	selectTicket: "Wählen Sie die Anzahl der Tickets aus",
	remove: "Entfernen",
	experiencescart: "Erfahrungen Warenkorb",
	total: "Gesamt",
	confirm: "Bestätigen",
	voucheraccepted: "Gutschein auf dem Handy akzeptiert",
	immediateconfirmation: "Sofortige Bestätigung",
	freecancellation: "Kostenlose Stornierung",
	upto24hours: "Bis zu 24 Stunden vorher",
	continuepurchase: "Weiter einkaufen",
	noexperiencesoncart: "Noch keine Erfahrungen im Warenkorb",
	warning: "Warnung",
	youhave: "Sie haben",
	minutestoendthebooking:
		"Minuten, um die Buchung abzuschließen. Stellen Sie sicher, dass Sie alle erforderlichen Informationen haben.",
	timeended: "Zeit abgelaufen!",
	verifyifjourneyavailable: "Überprüfen Sie, ob die Reise noch verfügbar ist.",
	travelerdata: "Reisend",
	discountcode: "Rabattcode",
	optional: "Optional",
	insertdiscountcode: "Rabattcode eingeben",
	apply: "Anwenden",
	generalconditionsandprivacy:
		"Allgemeine Geschäftsbedingungen und Datenschutz",
	generalconditionsandcancellation:
		"Allgemeine Geschäftsbedingungen und Stornierungsbedingungen",
	privacypolicy: "Datenschutzrichtlinie",
	within: "innerhalb",
	atthetimeofconfirmation: "zum Zeitpunkt der Bestätigung",
	infoonpayment: "Zahlungsinformationen",
	deposittobepaid: "Anzahlung zu zahlen",
	balancetobepaidby: "Restbetrag zu zahlen bis",
	bookwithbanktransfer: "Buchen Sie mit Banküberweisung",
	bookwithcreditcard: "Jetzt mit Kreditkarte buchen",
	paytheadvancepayment: "Zahlen Sie die Anzahlung in 3 Raten ab",
	withoutinterest: "ohne Zinsen",
	errorinbooking: "Fehler bei der Buchung",
	choodethemethod: "Wählen Sie die Methode, die am besten zu Ihnen passt",
	bookandpay: "Buchen und bezahlen",
	booktravel: "Buchen Sie die Reise",
	agencyrightsalreadyincluded: "Agenturrechte bereits im Preis enthalten",
	experiencesrecap: "Erfahrungen Zusammenfassung",
	insertrequestinfo:
		"Geben Sie die erforderlichen Daten ein, um mit der Buchung fortzufahren.",
	acceptgeneralconditions:
		"Akzeptieren Sie die allgemeinen Geschäftsbedingungen",
	confirmbooking: "Buchung bestätigen",
	experiencesnotfound: "Keine Erfahrungen gefunden",
	filtersearch: "Filtern Sie Ihre Suche",
	searchadestination: "Suchen Sie ein Ziel",
	noresults: "Keine Ergebnisse",
	readless: "Weniger lesen",
	sharewithfriends: "Teilen Sie diese Erfahrung mit Freunden und Familie",
	languages: "Sprachen",
	groupsize: "Gruppengröße",
	immediateconfirmation: "Sofortige Bestätigung",
	animalsaccepted: "Tiere akzeptiert",
	experienceforanyone: "Erfahrung für jeden",
	cancellationterms: "Stornierungsbedingungen",
	fullpricefrom: "Voller Preis ab",
	reducedby: "Reduziert um",
	share: "Teilen",
	discovermore: "Mehr entdecken",
	discovermorehotel: "Erfahren Sie mehr über das Hotel",
	discoverlesshotel: "Schließen Sie die Hotelinformationen",
	searchexperiences: "Suchen Sie nach Erfahrungen",
	option: "Option",
	from: "von",
	to: "zu",
	locality: "Lokalität",
	viewonthemap: "Auf der Karte anzeigen",
	view: "Sie sehen",
	adult: "Erwachsener",
	children: "Kinder",
	child_age: "Kindesalter",
	close: "Schließen",
	travelers: "Reisende",
	adults: "Erwachsene",
	and: "und",
	child: "Kind",
	year: "Jahr",
	years: "Jahre",
	of: "von",
	chooseyourjourney: "Wählen Sie Ihre Reise",
	arrivaldate: "Ankunftsdatum",
	whendoyouwanttoleave: "Wann möchten Sie abreisen?",
	searchexperiencesorlocality: "Suchen Sie nach Erfahrungen oder Orten",
	experiencesnotavailable:
		"Am gewünschten Ort sind keine Erlebnisse verfügbar. Schauen Sie sich diese ein paar Kilometer entfernt an",
	accommodationsNotAvailable: "Am gewünschten Ort ist keine Unterkunft verfügbar. Schauen Sie sich diese an, nur ein paar Kilometer entfernt",
	smartjourneysnotfound:
		"Leider haben wir noch keine Reisen, die Ihrer Suche entsprechen.",
	filterbylocalityorjourneyname: "Filtern Sie nach Ort oder Reisename",
	viaggismartfound: "Smart Trips gefunden",
	smarttrips: "Intelligentes Reisen",
	travelsolutions:
		"Komplette Reisevorschläge, die von unseren lokalen Experten für Sie erstellt wurden.",
	personalizationavailable:
		"Sie können sie personalisieren, indem Sie die Unterkunft und Erlebnisse auswählen, die Sie bevorzugen.",
	travelduration: "Reisedauer",
	durationnights: "Dauer (Nächte)",
	night: "nacht",
	reset: "Zurücksetzen",
	refund: "Rimborso",
	experiencesincluded: "Erfahrungen enthalten",
	duration: "Dauer",
	from: "von",
	days: "Tage",
	day: "Tag",
	hours: "Stunden",
	hour: "Stunde",
	minutes: "Minuten",
	minute: "Minute",
	thethemes: "Die Themen",
	nottobemissed: "Nicht zu verpassen",
	poi: "Sehenswürdigkeiten",
	months: "Genuar,Februar,März,April,Mai,Juni,Juli,August,September,Oktober,November,Dezember",
	localexpertsopinion: "Meinung lokaler Experten",
	tovisitifyouhavetime: "Zu besuchen, wenn Sie Zeit haben",
	itdeservesavisit: "Es lohnt sich einen Besuch",
	donotmissit: "Verpassen Sie es nicht",
	week: "Woche",
	who: "Wer",
	when: "Wann",
	travelersdata: "Reisendendaten",
	inserttravelersdata: "Geben Sie die Daten der Reisenden ein",
	traveler: "Reisender",
	add: "Hinzufügen",
	date: "Datum",
	experience: "Erfahrung",
	seeaccommodation: "",
	changehotel: "Hotel ändern",
	select: "Wählen",
	generalconditions: "Allgemeine Geschäftsbedingungen",
	cancellationpolicy: "Stornierungsbedingungen",
	therearenoexperiencesinserted: "Es sind keine Erfahrungen eingefügt",
	chooseRoom: "Wählen sie den raum",
	timetable: "Std.",
	chooseTime: "Wählen Sie eine Zeit",
	chooseLanguage: "Wählen Sie Ihre Sprache",
	ticketType: "Tickettyp",
	loading: "Wir holen die Daten für Sie ab...",
	AddContinue: "Hinzufügen und fortfahren",
	allOfItaly: "Ganz Italien",
	accommodationNotIncluded: "Das ausgewählte Erlebnis ist an den ausgewählten Daten nicht verfügbar.",
	chooseAnotherAccommodation: "Ändern Sie das Datum oder fügen Sie ein anderes Erlebnis hinzu",
	nocancellationterms: "Keine Stornierungsbedingungen",
	experienceIsNotAvailable: "Die Erfahrung ist an den ausgewählten Daten nicht verfügbar.",
	addAnotherExperience: "Fügen Sie eine andere Erfahrung hinzu",
	itinerarySummary: "Zusammenfassung der Reiseroute",
	toBePaidImmediately: "Sofort zu bezahlen",
	toBePaidBy: "Zu zahlen durch",
	enterDetails: "Geben Sie Ihre Daten ein",
	editDetails: "Geben Sie Ihre Daten ein",
	thecodeinsertedcouldnotbeapplied: "Der eingegebene Code konnte nicht angewendet werden",
	verifythecode: "Überprüfen Sie den Code",
	discountcodecorrectlyappliedpriceupdated: "Rabattcode korrekt angewendet, Preis aktualisiert",
	thediscountcodehasbeenappliedcorrectly: "Der Rab attcode wurde korrekt angewendet",
	priceRoom: "Preis pro Zimmer pro Aufenthalt",
	terms: "Bedingungen",
	oneofthechosenproductsisnologneravailable: "Eines der ausgewählten Produkte ist nicht mehr verfügbar. Bitte überprüfen Sie Ihre Reiseroute.",
	chooseTravel: "Wählen Sie Ihre Reise",
	personalize: "Personalisieren",
};
