import { createApp, h } from "vue";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import { createHead } from "@unhead/vue";
import wrapper from "vue3-webcomponent-wrapper";
import Forms from "./components/Forms.vue";

import it from "./locales/it";
import en from "./locales/en";
import de from "./locales/de";
import fr from "./locales/fr";
import ru from "./locales/ru";

const i18n = createI18n({
	legacy: false,
	locale: "it",
	fallbackLocale: "it",
	messages: {
		it: it,
		en: en,
		de: de,
		fr: fr,
		ru: ru
	}
});

const createAppWrapper = (component) => {
	const app = createApp(component)
		.use(createPinia())
		.use(i18n)
		.use(createHead());
	return app;
};

const webComponent = wrapper(Forms, createAppWrapper, h);
if (!window.customElements.get("kinboo-forms")) {
	window.customElements.define("kinboo-forms", webComponent);
}
