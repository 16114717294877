<script setup lang="ts">
import { useHead } from "@unhead/vue";
import Forms from '@/components/Forms/Index.vue'
import { useWcParams } from "@/composables/params";

const props = defineProps({
    display: {
        type: String,
        default: null
    },
    lang: {
        type: String,
        default: null
    },
    type: {
        type: String,
        default: null
    },
    params: {
        type: String,
        default: null
    }
})

const {wcParams, setWcParams} = useWcParams();
setWcParams(props);

useHead({
    link: [
        {
            href: 'https://fonts.googleapis.com',
            rel: 'preconnect',
        },
        {
            href: 'https://fonts.gstatic.com',
            rel: 'preconnect',
            crossorigin: ''
        },
        {
            href: 'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
            rel: 'stylesheet',
        }
    ]
});

</script>

<template>
    <Suspense>
        <Forms v-if="wcParams.display" />
    </Suspense>
</template>
