import { add } from "date-fns";

export default {
	hoteldetails: "Детали отеля",
	hotelincluded: "Отель включен",
	expand: "Развернуть",
	adults: "взрослый | взрослые",
	childs: "ребенок | дети",
	selectedrooms: "Выбранная комната | Выбранные комнаты",
	paymentsummary: "Обзор платежей",
	advancepayment: "Аванс",
	balance: "Баланс",
	paymentoptions: "Способы оплаты",
	onsitecosts: "Расходы на месте",
	creditcard: "Кредитная карта",
	banktransfer: "Банковский перевод",
	scalapaydetails: "Оплата в 3 рассрочки без процентов",
	cannotconfirm:
		"Вы пока не можете оплатить, чтобы подтвердить эту бронь. Пожалуйста, дождитесь нашего сообщения, чтобы получить доступ.",
	nofilefound: "Не найдено файлов!",
	personaldata: "Персональные данные",
	stay: "Пребывание",
	flights: "Рейсы",
	ships: "Корабли",
	transfer: "Трансфер",
	insurance: "Страховка",
	cancellations: "Отмены",
	payments: "Платежи",
	filenumber: "Номер дела",
	requestsummary: "Обзор запроса",
	seeall: "Посмотреть все",
	thankyoumsg: "Спасибо, что поделились своим мнением!",
	sendreview: "Отправить свой обзор",
	reservationstatus: "Статус бронирования",
	openingperiod: "Период работы",
	dal: "С",
	al: "По",
	toproceedmsg:
		"Для продолжения оплаты и подтверждения бронирования пожалуйста, введите ваши данные для выставления счета и дайте согласие на использование.",
	wannaconfirm1: `Хотите подтвердить?`,
	wannaconfirm2: `Запросите обновление предложения.`,
	bookingcancelled:
		"Ваше бронирование было ОТМЕНЕНО. Для получения дополнительной информации свяжитесь с",
	emailsubject: "Запрос на обновление предложения №",
	youhavestill: "у вас все еще есть",
	toconfirm: "для подтверждения этого предложения",
	clickhere: "нажмите здесь",
	updateproposal: "Обновите свое предложение",
	downloaddocs: "Скачать документы поездки",
	welcome: "Добро пожаловать",
	room: "Комната",
	for: "для",
	age: "возраст",
	treatment: "Обращение",
	roomdescription: "Описание номера",
	structureextras: "Дополнительные расходы, подлежащие оплате в структуре",
	gratis: "бесплатно",
	basecost: "Базовая стоимость",
	discountalreadyapplied: "Скидки уже применены",
	getmore: "Get More",
	extra: "Дополнительные расходы, подлежащие оплате в момент бронирования",
	roomnetcost: "Чистая стоимость номера",
	reviews: "отзывы",
	arrival: "Прибытие",
	leaving: "Отъезд",
	nights: "Ночи",
	rooms: "Комнаты",
	persons: "Люди",
	onsitecostsexcluded: "исключая дополнительные расходы на месте",
	onsitecoststotal: "Общие расходы на месте",
	go: "В ПУТИ",
	return: "НАЗАД",
	route: "Маршрут",
	passengers: "Пассажиры",
	accommodation: "Размещение",
	transportation: "Транспорт",
	plate: "Номерной знак",
	company: "Компания",
	transferroute: "Трансфер",
	vehicle: "Транспортное средство",
	flight: "Рейс",
	otherdiscounts: "Другие скидки",
	packageextras: "Дополнительные услуги пакета",
	axa: "Полис AXA 360°",
	cancellationinsurance: "Отмена, Медицинские расходы, Багаж",
	insuredpersons: "Застрахованные лица",
	readconditions: "Читайте условия страхования",
	packages: "Пакеты",
	companydata: "Компания данные",
	custmerdata: "Персональные данные клиента",
	travelerdata: "Данные путешественника",
	passengersdata: "Данные пассажиров",
	passenger: "Пассажир",
	privacyconfirmation:
		"Я подтверждаю, что прочитал и принимаю Политику конфиденциальности и файлы cookie.",
	newsletterconfirmation:
		"Я хочу получать информацию и предложения по электронной почте от Charming.",
	updatedata: "Обновить ваши данные",
	customernotes: "ЗАМЕЧАНИЯ КЛИЕНТА",
	structurenotes: "ЗАМЕЧАНИЯ (Заметки для клиента)",
	bookingnotes: "ЗАМЕЧАНИЯ (Заметки для бронирования)",
	vouchernotes: "ЗАМЕЧАНИЯ КУПОНА",
	packagenotes: "ЗАМЕЧАНИЯ ПАКЕТА",
	travelinsurance: "Страхование путешественников",
	details: "детали",
	ship: "Корабль",
	costssummary: "Обзор стоимости и платежей",
	totalpract: "Общая практика",
	holidaystart: "Начало отпуска",
	holidaystop: "Конец отпуска",
	location: "Местоположение",
	printfile: "Распечатать вашу практику",
	rating: "Рейтинг",
	services: "Услуги",
	cleaning: "Уборка",
	staff: "Персонал",
	comfort: "Комфорт",
	qualityprice: "Соотношение цены и качества",
	catering: "Обслуживание питания",
	ratestructure: "Оцените структуру",
	leavecommentstructure: "Оставьте отзыв о структуре",
	positivecomment: "Положительный отзыв",
	negativecomment: "Отрицательный отзыв",
	rateourservice: "Оцените наш сервис",
	howwasreservewithus: "Как прошло бронирование с нами?",
	leavecomment: "Оставить комментарий",
	ratelocation: "Оцените местоположение",
	none: "Ни один",
	youngcouples: "Молодые пары",
	maturecouples: "Зрелые пары",
	familywithyoungsons: "Семья с маленькими детьми",
	familywithbigsons: "Семья с большими детьми",
	withfriends: "С друзьями",
	singletravellers: "Одинокие путешественники",
	groupstravel: "Групповые поездки",
	save: "Сэкономьте {savedAmount}!",
	costofyourholiday:
		"Стоимость вашего отпуска составляет {totalAmount} вместо {undiscountedAmount}",
	writelike: "Напишите, что вам понравилось",
	writedislike: "Напишите, что вам не понравилось",
	startingfrom: "начиная с",
	pho: "Тел.",
	atglance: "В одном взгляде",
	charmingopinion: "Мнение Charming",
	days: "Дни",
	hours: "Часы",
	minutes: "Минуты",
	seconds: "Секунды",
	paycreditcard: "Оплатить кредитной картой",
	paythreetrances: "Оплатить в три платежа на сумму {amount}",
	paybanktransfer: "Оплатить банковским переводом",
	pay: "Оплатить",
	howtodeposit: "Как сделать депозит банковским или почтовым переводом",
	close: "Закрыть",
	voucher: "Ваучер",
	novoucheravailable: "Документ поездки для этой услуги пока недоступен.",
	yes: "Да",
	no: "Нет",
	toTop: "Наверх",
	topayuntil: "К оплате до",
	paidon: "Оплачено",
	costtransf: "Стоимость трансфера",
	costtottransfdiscounts: "Скидка применена",
	costtottransf: "Общая стоимость трансфера",
	at: "в",
	validations: {
		required: "Это поле обязательно для заполнения.",
		maxLength: "Максимальная длина поля должна быть {max} символов.",
		numeric: "Это поле должно содержать только цифры.",
		email: "Введите действительный адрес электронной почты.",
		taxCode: "Это поле должно содержать действительный налоговый код."
	},
	roomcost: "СТОИМОСТЬ НОМЕРА",
	requiredfield: "Обязательное поле",
	arrivaldate: "Дата прибытия",
	departuredate: "Дата выезда",
	adultslabel: "Взрослые",
	childslabel: "Дети",
	regions: "Регионы",
	cities: "Города",
	noresults: "Нет результатов",
	search: "Поиск",
	edit: "Редактировать",
	experiencesfound: "найденные опыты",
	experiences: "Опыты",
	addExperience: "Добавить опыт",
	discoveractivities:
		"Откройте для себя мероприятия, выбранные для вас нашей командой.",
	filters: "Фильтры",
	appliedfilters: "Примененные фильтры",
	closefilters: "Закрыть фильтры",
	filter: "Фильтр",
	changesearch: "Изменить поиск",
	showmoreexp: "Покажите другой опыт.",
	showmoretravel: "Показать больше умных поездок",
	showtravel: "Показать поездки",
	update: "Oбновлять",
	chooseyourinterests: "Выберите ваши интересы.",
	applyfilters: "Примените фильтры.",
	language: "Язык",
	selectLanguage: "Выберите язык",
	whatdestination: "Куда бы вы хотели отправиться?",
	whentravel: "Когда ты поедешь в отпуск?",
	withtravel: "С кем ты уйдешь?",
	whatkindoftraveler: "Какой вид путешественника вы?",
	duration: "Продолжительность",
	perperson: "За человека",
	howumuchtospend: "Сколько вы хотите потратить?",
	uniquejourney: "Предложено вам местными экспертами",
	clear: "Очистить",
	allitaly: "Вся Италия",
	experiencescart: "Корзина опытов",
	selectyourinterests: "Выберите свои интересы",
	thankspurchase: "Спасибо за покупку!",
	emailwithrecap:
		"Вскоре вы получите электронное письмо с подтверждением и кратким описанием приобретенный опыт. Хорошо повеселиться!",
	continuenavigation: "Продолжить навигацию",
	thanksforusing: "Спасибо за использование нашего сервиса!",
	highlights: "Основные моменты",
	description: "Описание",
	importantinformation: "Важная информация",
	includesnotincludes: "Включает и не включает",
	meetingpoint: "Место встречи",
	cancellationpolicy: "Политика отмены",
	temesofthisexperience: "Темы этого опыта",
	bookingdata: "Данные бронирования",
	verifytheavailability: "Проверьте наличие",
	selecttravel: "Выбрать эту поездку",
	dataaddon: "Дополнительные данные",
	weneedmoreinformation: "Чтобы забронировать этот опыт, нам нужна дополнительная информация.",
	choosethetipology: "Выберите типологию",
	bringyourjourneytolife: "Сделайте свое путешествие незабываемым",
	theteamof: "Команда",
	bestoriceguaranteed: "Лучшая цена гарантирована",
	advicefromlocalexperts: "Советы от местных экспертов",
	assistance77: "Помощь 24/7",
	safepayment: "Безопасная оплата",
	ticketandextra: "Билет и дополнительно",
	ticket: "билет",
	selectTicket: "выберите количество билетов",
	remove: "удалить",
	experiencescart: "Корзина опытов",
	total: "Итого",
	confirm: "Подтвердить",
	voucheraccepted: "Ваучер принят на мобильный",
	immediateconfirmation: "Мгновенное подтверждение",
	freecancellation: "Бесплатная отмена",
	upto24hours: "До 24 часов",
	continuepurchase: "Продолжить покупку",
	noexperiencesoncart: "Еще нет опытов в корзине",
	warning: "Предупреждение",
	youhave: "У вас есть",
	minutestoendthebooking:
		"минуты, чтобы завершить бронирование. Убедитесь, что у вас есть все необходимые данные.",
	timeended: "Время вышло!",
	verifyifjourneyavailable: "Проверьте, доступно ли путешествие.",
	travelerdata: "Данные путешественника",
	discountcode: "Промокод",
	optional: "Необязательно",
	book: "Забронировать",
	apply: "Применить",
	generalconditionsandprivacy: "Общие условия и конфиденциальность",
	generalconditionsandcancellation: "Общие условия и политика отмены",
	privacypolicy: "Политика конфиденциальности",
	within: "в течение",
	atthetimeofconfirmation: "в момент подтверждения",
	infoonpayment: "Информация о платеже",
	deposittobepaid: "Депозит для оплаты",
	balancetobepaidby: "Остаток для оплаты до",
	bookwithbanktransfer: "Забронировать банковским переводом",
	bookwithcreditcard: "Забронировать сейчас кредитной картой",
	paytheadvancepayment: "Оплатить аванс",
	withoutinterest: "без процентов",
	errorinbooking: "Ошибка при бронировании",
	choodethemethod: "Выберите метод оплаты",
	bookandpay: "Забронировать и оплатить",
	booktravel: "Забронировать поездку",
	agencyrightsalreadyincluded: "Права агентства уже включены в цену",
	experiencesrecap: "Краткое описание опытов",
	insertrequestinfo:
		"Введите запрашиваемые данные для продолжения бронирования.",
	acceptgeneralconditions: "Примите общие условия",
	confirmbooking: "Подтвердить бронирование",
	experiencesnotfound: "Опыты не найдены",
	filtersearch: "Фильтр поиска",
	searchadestination: "Поиск места назначения",
	noresults: "Нет результатов",
	readless: "Читать меньше",
	sharewithfriends: "Поделитесь этим опытом с друзьями и семьей",
	languages: "Языки",
	groupsize: "Размер группы",
	immediateconfirmation: "Мгновенное подтверждение",
	animalsaccepted: "Животные принимаются",
	experienceforanyone: "Опыт для всех",
	cancellationterms: "Условия отмены",
	fullpricefrom: "Полная цена от",
	reducedby: "Уменьшено на",
	share: "Поделиться",
	discovermore: "Узнайте больше",
	discovermorehotel: "Узнайте больше об отеле",
	discoverlesshotel: "Закрыть информацию об отеле",
	searchexperiences: "Поиск опытов",
	option: "Опция",
	from: "От",
	to: "До",
	locality: "Местность",
	viewonthemap: "Посмотреть на карте",
	view: "Понимаете",
	adult: "Взрослый",
	children: "Дети",
	child_age: "Возраст ребенка",
	close: "Закрыть",
	travelers: "Путешественники",
	adults: "Взрослые",
	and: "и",
	child: "ребенок",
	year: "год",
	years: "лет",
	of: "из",
	chooseyourjourney: "Выберите свое путешествие",
	arrivaldate: "Дата прибытия",
	whendoyouwanttoleave: "Когда вы хотите уехать?",
	searchexperiencesorlocality: "Поиск опытов или местности",
	experiencesnotavailable:
		"В запрошенном месте нет доступных впечатлений. Посмотрите это в нескольких километрах от",
	accommodationsNotAvailable: "В запрашиваемом месте нет доступных вариантов размещения. Взгляните на них, всего в нескольких километрах отсюда.",
	smartjourneysnotfound:
		"К сожалению, у нас пока нет поездок, соответствующих вашему запросу.",
	filterbylocalityorjourneyname:
		"Фильтровать по местоположению или названию поездки",
	viaggismartfound: "найдены умные поездки",
	smarttrips: "умное путешествие",
	travelsolutions:
		"Полные предложения путешествий, созданные для вас нашими местными экспертами.",
	personalizationavailable:
		"Вы можете персонализировать их, выбрав жилье и впечатления, которые вы предпочитаете.",
	travelduration: "Продолжительность путешествия",
	durationnights: "Продолжительность (ночей)",
	night: "ночь",
	reset: "Сброс",
	refund: "Rimborso",
	experiencesincluded: "Включенные опыты",
	duration: "Продолжительность",
	from: "От",
	days: "Дни",
	day: "День",
	hours: "Часы",
	hour: "Час",
	minutes: "Минуты",
	minute: "Минута",
	thethemes: "Темы",
	nottobemissed: "Не пропустите",
	poi: "Точка интереса",
	months: "Генварь,февраль,март,апрель,май,июнь,июль,август,сентябрь,октябрь,ноябрь,декабрь",
	localexpertsopinion: "Мнение местных экспертов",
	tovisitifyouhavetime: "Посетить, если у вас есть время",
	itdeservesavisit: "Это заслуживает посещения",
	donotmissit: "Не пропустите",
	week: "неделя",
	who: "Кто",
	when: "Когда",
	travelersdata: "Данные путешественников",
	inserttravelersdata: "Введите данные всех путешественников",
	traveler: "Путешественник",
	add: "Добавить",
	date: "дата",
	experience: "Опыт",
	seeaccommodation: "",
	changehotel: "Изменить отель",
	select: "Выбрать",
	generalconditions: "Общие условия",
	cancellationpolicy: "Политика отмены",
	changehotel: "Изменить отель",
	therearenoexperiencesinserted: "Опыт не вставлен",
	chooseRoom: "Выберите номер",
	timetable: "часы",
	chooseTime: "выбрать время",
	chooseLanguage: "Выберите свой язык",
	ticketType: "Тип билета",
	loading: "Получаем данные для вас...",
	AddContinue: "Добавить и продолжить",
	allOfItaly: "Вся Италия",
	accommodationNotIncluded: "Объект, включенный в эту поездку, недоступен на выбранные даты.",
	chooseAnotherAccommodation: "Выбрать другое жилье",
	nocancellationterms: "Нет условий отмены",
	experienceIsNotAvailable: "Этот опыт недоступен на выбранные даты.",
	addAnotherExperience: "Добавить другой опыт",
	itinerarySummary: "Краткое описание маршрута",
	toBePaidImmediately: "К оплате немедленно",
	toBePaidBy: "Должен быть оплачен",
	enterDetails: "Введите свои данные",
	editDetails: "Редактируйте свои данные",
	thecodeinsertedcouldnotbeapplied: "Введенный код не может быть применен",
	verifythecode: "Проверьте код",
	discountcodecorrectlyappliedpriceupdated: "Промокод правильно применен, цена обновлена",
	thediscountcodehasbeenappliedcorrectly: "Промокод был правильно применен",
	chooseRoom: "Выберите номер",
	priceRoom: "Цена за номер за проживание",
	terms: "условия",
	enterYourDetails: "Введите свои данные",
	editYourDetails: "Изменить свои данные",
	chooseTravel: "Выберите поездку",
	personalize: "Персонализировать",
	personalizeItinerary: "Перейдите к настройке вашего маршрута",
	customizeTrip: "Настройте или подтвердите поездку",
	includes: "Включает",
	notincludes: "Не включает",
	doyouwanttoseemoreinthisdestination: "Хотите увидеть больше в этом месте?",
	herearesuggestionsforyou: "Вот предложения для вас",
	transferClaim: "Вы также хотите трансфер на зарезервированном автомобиле из аэропорта/порта в",
	addTransfer: "Добавить трансфер",
};
