export default {
	hoteldetails: "Hotel Facilities",
	hotelincluded: "Hotel Included",
	adults: "adult | adults",
	childs: "child | children",
	selectedrooms: "Selected Room | Selected Rooms",
	paymentsummary: "Payment Summary",
	advancepayment: "Deposit",
	balance: "Balance",
	paymentoptions: "Payment Options",
	onsitecosts: "On-site Costs",
	creditcard: "Credit Card",
	banktransfer: "Bank Transfer",
	scalapaydetails: "Pay in 3 interest-free instalments",
	cannotconfirm:
		"You cannot yet pay to confirm this booking. Please wait for our communication to proceed.",
	nofilefound: "No file found!",
	personaldata: "Personal Data",
	stay: "Accommodation",
	flights: "Flights",
	ships: "Ships",
	transfer: "Transfer",
	insurance: "Insurance",
	cancellations: "Cancellations",
	payments: "Payments",
	filenumber: "File No.",
	requestsummary: "Request Summary",
	seeall: "See All",
	thankyoumsg: "Thank you for sharing your opinion!",
	sendreview: "Send Your Review",
	reservationstatus: "Reservation Status",
	openingperiod: "Opening Period",
	dal: "From",
	al: "To",
	toproceedmsg:
		"To proceed with payment and booking confirmation, please enter your billing details and give consent for use.",
	wannaconfirm1: "Do you still want to confirm?",
	wannaconfirm2: "Request proposal update.",
	bookingcancelled:
		"Your booking has been CANCELLED. For more information, please contact",
	emailsubject: "Proposal Update Request No.",
	youhavestill: "you still have",
	toconfirm: "to confirm this offer",
	clickhere: "click here",
	updateproposal: "Update Your Proposal",
	downloaddocs: "Download Travel Documents",
	welcome: "Welcome",
	room: "Room",
	for: "for",
	age: "age",
	treatment: "Treatment",
	roomdescription: "Room Description",
	structureextras: "Structure Extras",
	gratis: "free",
	basecost: "Base Cost",
	discountalreadyapplied: "Discounts already applied",
	getmore: "Get More",
	extra: "Additional costs to be paid at the time of booking",
	roomnetcost: "Net Room Cost",
	reviews: "reviews",
	arrival: "Arrival",
	leaving: "Departure",
	nights: "Nights",
	rooms: "Rooms",
	persons: "Persons",
	onsitecostsexcluded: "excluding any on-site costs",
	onsitecoststotal: "Total on-site costs",
	go: "DEPARTURE",
	return: "RETURN",
	route: "Route",
	passengers: "Passengers",
	accommodation: "Accommodation",
	transportation: "Transportation",
	plate: "Plate",
	company: "Company",
	transferroute: "Transfer Route",
	vehicle: "Vehicle",
	flight: "Flight",
	otherdiscounts: "Other Discounts",
	packageextras: "Package Extras",
	axa: "AXA 360° Policy",
	cancellationinsurance: "Cancellation, Medical Expenses, Baggage",
	insuredpersons: "Insured Persons",
	readconditions: "See Insurance Conditions",
	packages: "Packages",
	companydata: "Company Data",
	custmerdata: "Personal Data",
	travelerdata: "Traveler Data",
	passengersdata: "Passenger Data",
	passenger: "Passenger",
	privacyconfirmation:
		"I confirm that I have read and accept the Privacy & Cookies.",
	newsletterconfirmation:
		"I want to receive information and offers via email from Charming.",
	updatedata: "Update Your Data",
	customernotes: "CUSTOMER NOTES",
	structurenotes: "NOTES (Structure for Customer)",
	bookingnotes: "NOTES (Booking for Customer)",
	vouchernotes: "VOUCHER NOTES",
	packagenotes: "PACKAGE NOTES",
	travelinsurance: "Travel Insurance",
	details: "Details",
	ship: "Ship",
	costssummary: "Costs and Payments Summary",
	totalpract: "Total File",
	holidaystart: "Holiday Start",
	holidaystop: "Holiday End",
	location: "Location",
	printfile: "Print Your File",
	rating: "Rating",
	services: "Services",
	cleaning: "Cleaning",
	staff: "Staff",
	comfort: "Comfort",
	qualityprice: "Quality-Price Ratio",
	catering: "Catering",
	ratestructure: "Rate the Structure",
	leavecommentstructure: "Leave a Review of the Structure",
	positivecomment: "Positive Review",
	negativecomment: "Negative Review",
	rateourservice: "Rate Our Service",
	howwasreservewithus: "How was booking with us?",
	leavecomment: "Leave a Comment",
	ratelocation: "Rate the Location",
	none: "None",
	youngcouples: "Young Couples",
	maturecouples: "Mature Couples",
	familywithyoungsons: "Family with Young Sons",
	familywithbigsons: "Family with Big Sons",
	withfriends: "With Friends",
	singletravellers: "Single Traveller",
	groupstravel: "Group Travel",
	save: "Save {savedAmount}!",
	costofyourholiday:
		"The cost of your holiday is {totalAmount} instead of {undiscountedAmount}",
	writelike: "Write what you liked",
	writedislike: "Write what you didn't like",
	startingfrom: "starting from",
	pho: "Tel.",
	atglance: "At a Glance",
	charmingopinion: "Charming Opinion",
	days: "Days",
	hours: "Hours",
	minutes: "Minutes",
	seconds: "Seconds",
	paycreditcard: "Pay by Credit Card",
	paythreetrances: "Pay in three instalments from {amount}",
	paybanktransfer: "Pay by Bank Transfer",
	pay: "Pay",
	howtodeposit: "How to pay by bank transfer or postal order",
	close: "Close",
	voucher: "Voucher",
	novoucheravailable:
		"The travel document for this service is not yet available.",
	yes: "Yes",
	no: "No",
	toTop: "Back to Top",
	topayuntil: "To be paid by",
	paidon: "Paid on",
	costtransf: "Transfer Cost",
	costtottransfdiscounts: "Discount applied",
	costtottransf: "Total transfer cost",
	at: "at",
	validations: {
		required: "This field is required.",
		maxLength: "The field must have a maximum length of {max} characters.",
		numeric: "The field must be a number.",
		email: "The field must be a valid email address.",
		taxCode: "The field must be a valid tax code."
	},
	roomcost: "ROOM COST",
	requiredfield: "Required field",
	arrivaldate: "Arrival Date",
	departuredate: "Departure Date",
	adultslabel: "Adults",
	childslabel: "Children",
	regions: "Regions",
	cities: "Cities",
	noresults: "No results",
	search: "Search",
	edit: "Edit",
	experiencesfound: "experiences found",
	experiences: "Experiences",
	addExperience: "Add Experience",
	discoveractivities: "Discover the activities selected for you by our Team.",
	filters: "Filters",
	appliedfilters: "Applied Filters",
	closefilters: "Close Filters",
	filter: "Filter",
	changesearch: "Change Search",
	showmoreexp: "Show more experiences",
	showmoretravel: "Show more travel ideas",
	showtravel: "Show travels",
	update: "Update",
	chooseyourinterests: "Choose your interests",
	applyfilters: "Apply Filters",
	language: "Language",
	selectLanguage: "Select a language",
	whatdestination: "Which destination would you like to visit?",
	whentravel: "When you go on holiday",
	withtravel: "Who will you leave with?",
	whatkindoftraveler: "What kind of traveler are you?",
	duration: "Duration",
	perperson: "Per person",
	howumuchtospend: "How much do you want to spend?",
	uniquejourney: "Suggested for you by Local Experts",
	clear: "Clear",
	allitaly: "All Italy",
	experiencescart: "Experiences Cart",
	selectyourinterests: "Select your interests",
	thankspurchase: "Thank you for your purchase",
	emailwithrecap:
		"You will shortly receive a confirmation email with a summary of the purchased experiences. Good fun!",
	continuenavigation: "Continue site navigation",
	thanksforusing: "Thanks for using",
	highlights: "Highlights",
	description: "Description",
	importantinformation: "Important Information",
	includesnotincludes: "Includes/Does not include",
	meetingpoint: "Meeting Point",
	cancellationpolicy: "Cancellation Policy",
	temesofthisexperience: "The themes of this experience",
	bookingdata: "DATA FOR BOOKING",
	verifytheavailability: "Check availability",
	selecttravel: "Choose this trip",
	dataaddon: "Additional data",
	weneedmoreinformation: "To book this experience we need some additional information.",
	choosethetipology: "Choose the typology",
	bringyourjourneytolife: "Bring your journey to life",
	theteamof: "The team of",
	bestoriceguaranteed: "Best Price Guaranteed",
	advicefromlocalexperts: "Advice from Local Experts",
	assistance77: "Assistance 7 days a week",
	safepayment: "Secure Payment",
	ticketandextra: "Ticket and Extra",
	ticket: "ticket",
	selectTicket: "Select the number of tickets",
	remove: "Remove",
	experiencescart: "Experiences Cart",
	total: "Total",
	confirm: "Confirm",
	voucheraccepted: "Voucher accepted on mobile",
	immediateconfirmation: "Immediate Confirmation",
	freecancellation: "Free Cancellation",
	upto24hours: "Up to 24 hours before",
	continuepurchase: "Continue shopping",
	noexperiencesoncart: "Still no experiences in the cart",
	warning: "Warning",
	youhave: "You have",
	minutestoendthebooking:
		"minutes to complete the booking. Make sure you have all the necessary information.",
	timeended: "Time's up!",
	verifyifjourneyavailable: "Check if the journey is still available.",
	travelerdata: "Traveler Data",
	discountcode: "Discount Code",
	optional: "Optional",
	insertdiscountcode: "Insert discount code",
	apply: "Apply",
	generalconditionsandprivacy: "General Conditions and Privacy",
	generalconditionsandcancellation:
		"General Conditions and Cancellation Policies",
	privacypolicy: "Privacy Policy",
	within: "within",
	atthetimeofconfirmation: "at the time of confirmation",
	infoonpayment: "Payment Information",
	deposittobepaid: "Deposit to be paid",
	balancetobepaidby: "Balance to be paid by",
	bookwithbanktransfer: "Book with bank transfer",
	bookwithcreditcard: "Book now with credit card",
	paytheadvancepayment: "Pay the deposit in 3 instalments from",
	withoutinterest: "without interest",
	errorinbooking: "Error in booking",
	choodethemethod: "Choose the method that suits you best",
	bookandpay: "Book and pay",
	booktravel: "Book the trip",
	agencyrightsalreadyincluded: "Agency rights already included in the price",
	experiencesrecap: "Experiences Summary",
	insertrequestinfo: "Enter the required data to proceed with the booking.",
	acceptgeneralconditions: "Accept the general conditions",
	confirmbooking: "Confirm Booking",
	experiencesnotfound: "No experiences found",
	filtersearch: "Filter your search",
	searchadestination: "Search a destination",
	noresults: "No results",
	readless: "Read less",
	sharewithfriends: "Share this experience with friends and family",
	languages: "Languages",
	groupsize: "Group Size",
	immediateconfirmation: "Immediate Confirmation",
	animalsaccepted: "Animals Accepted",
	experienceforanyone: "Experience for Anyone",
	cancellationterms: "Cancellation Terms",
	fullpricefrom: "Full price from",
	reducedby: "Reduced by",
	share: "Share",
	discovermore: "Discover More",
	discovermorehotel: "Find out more about the hotel",
	discoverlesshotel: "Close hotel info",
	searchexperiences: "Search Experiences",
	option: "Option",
	from: "from",
	to: "to",
	locality: "Locality",
	viewonthemap: "View on Map",
	view: "See",
	adult: "Adult",
	children: "Children",
	child_age: "Child Age",
	close: "Close",
	travelers: "Travelers",
	adults: "Adults",
	and: "and",
	child: "Child",
	year: "year",
	years: "years",
	of: "of",
	chooseyourjourney: "Choose your journey",
	arrivaldate: "Arrival Date",
	whendoyouwanttoleave: "When do you want to leave?",
	searchexperiencesorlocality: "Search for experiences or locality",
	experiencesnotavailable:
		"There are no experiences available in the requested location. Check out these a few km from ",
	accommodationsNotAvailable: "There is no accommodation available in the requested location. Take a look at these, just a few km away",
	smartjourneysnotfound:
		"Sorry, we don't have any travel ideas matching your search yet.",
	filterbylocalityorjourneyname: "Filter by location or trip name",
	viaggismartfound: "travel ideas found",
	smarttrips: "Travel ideas",
	travelsolutions:
		"Complete travel proposals created for you by our Local Experts.",
	personalizationavailable:
		"You can personalize them by choosing the accommodation and experiences you prefer.",
	travelduration: "Travel Duration",
	durationnights: "Duration (nights)",
	night: "night",
	reset: "Reset",
	expand: "Expand",
	refund: "Rimborso",
	experiencesincluded: "Experiences Included",
	duration: "Duration",
	from: "from",
	days: "days",
	day: "day",
	hours: "hours",
	hour: "hour",
	minutes: "minutes",
	minute: "minute",
	thethemes: "The Themes",
	nottobemissed: "Not to be missed",
	poi: "Points of Interest",
	months: "Genuary,February,March,April,May,June,July,August,September,October,November,December",
	localexpertsopinion: "Local Experts Opinion",
	tovisitifyouhavetime: "To visit if you have time",
	itdeservesavisit: "It deserves a visit",
	donotmiss: "Do not miss",
	week: "week",
	who: "Who",
	when: "When",
	travelersdata: "Dati di tutti i viaggiatori",
	inserttravelersdata: "Inserisci i dati di tutti i viaggiatori",
	traveler: "Viaggiatore",
	add: "Add",
	date: "Date",
	experience: "Experience",
	seeaccommodation: "see accommodation",
	changehotel: "change accommodation",
	select: "Select",
	generalconditions: "General Conditions",
	cancellationpolicy: "Cancellation Policy",
	changehotel: "Change Hotel",
	therearenoexperiencesinserted: "There are no experiences inserted",
	chooseRoom: "Choose the room",
	timetable: "Timetable",
	chooseTime: "Choose a time",
	chooseLanguage: "Choose your language",
	ticketType: "Ticket type",
	loading: "Retrieving data for you...",
	AddContinue: "Add and continue",
	allOfItaly: "All of Italy",
	accommodationNotIncluded: "The property included in this trip is not available on the selected dates.",
	chooseAnotherAccommodation: "Choose another accommodation",
	nocancellationterms: "No cancellation terms specified",
	experienceIsNotAvailable: "The experience included in this trip is not available on the selected dates.",
	addAnotherExperience: "Change date or  add another experience",
	itinerarySummary: "Itinerary summary",
	toBePaidImmediately: "To be paid immediately",
	toBePaidBy: "To be paid by",
	enterDetails: "Enter your details",
	editDetails: "Edit your details",
	thecodeinsertedcouldnotbeapplied: "The code inserted could not be applied. Please try again.",
	verifythecode: "Verify the code",
	discountcodecorrectlyappliedpriceupdated: "Discount code correctly applied. Price updated.",
	thediscountcodehasbeenappliedcorrectly: "The discount code has been applied correctly",
	chooseRoom: "Choose your room",
	priceRoom: "Price per room per stay",
	terms: "Terms",
	oneofthechosenproductsisnologneravailable: "One of the chosen products is no longer available. Please check your itinerary.",
	chooseTravel: "Choose your trip",
	personalize: "Personalize",
	personalizeItinerary: "Proceed to customize your Itinerary",
	customizeTrip: "Customize or confirm your trip",
	includes: "Included",
	notincludes: "Not include",
	doyouwanttoseemoreinthisdestination: "Do you want to see more in this destination?",
	herearesuggestionsforyou: "Here are some suggestions for you",
	transferClaim: "Do you also want a transfer by reserved car from the airport/port to",
	addTransfer: "Add transfer",
};
