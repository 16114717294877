
export default {
	hoteldetails: "Caratteristiche dell'hotel",
	adults: "adulto | adulti",
	childs: "bambino | bambini",
	selectedrooms: "Camera selezionata | Camere selezionate",
	paymentsummary: "Riepilogo pagamenti",
	advancepayment: "Acconto",
	balance: "Saldo",
	paymentoptions: "Modalità di pagamento",
	onsitecosts: "Costi da pagare in loco",
	creditcard: "Carta di credito",
	banktransfer: "Bonifico bancario",
	scalapaydetails: "Paga in 3 rate senza interessi",
	cannotconfirm:
		"Ancora non puoi pagare per confermare questa prenotazione. Attendi la nostra comunicazione per poter accedere.",
	nofilefound: "Nessuna pratica trovata!",
	personaldata: "Dati",
	stay: "Alloggio",
	flights: "Voli",
	ships: "Navi",
	transfer: "Transfer",
	insurance: "Assicurazione",
	cancellations: "Cancellazioni",
	payments: "Pagamenti",
	filenumber: "PRATICA N°",
	requestsummary: "Riepilogo Richiesta",
	seeall: "Vedi tutto",
	thankyoumsg: "Ti ringraziamo per aver condiviso il tuo parere!",
	sendreview: "Invia la tua recensione",
	reservationstatus: "Stato della prenotazione",
	openingperiod: "Periodo di Apertura",
	dal: "Dal",
	al: "Al",
	toproceedmsg:
		"Per procedere col pagamento e la conferma della prenotazione ti chiediamo di inserire i tuoi dati per la fatturazione e dare il consenso all’utilizzo.",
	wannaconfirm1: "Vuoi ancora confermare?",
	wannaconfirm2: "Chiedi l'aggiornamento della proposta.",
	bookingcancelled:
		"La tua prenotazione è stata CANCELLATA. Per maggiori informazioni contatta",
	emailsubject: "Richiesta di aggiornamento della proposta n°",
	youhavestill: "hai ancora",
	toconfirm: "per confermare questa offerta",
	clickhere: "clicca qui",
	updateproposal: "Aggiorna la tua proposta",
	downloaddocs: "Scarica i documenti di Viaggio",
	welcome: "Benvenuto",
	room: "Camera",
	for: "per",
	age: "età",
	treatment: "Trattamento",
	roomdescription: "Descrizione della camera",
	structureextras: "Costi supplementari da pagare in struttura",
	gratis: "gratuito",
	basecost: "Costo base",
	discountalreadyapplied: "Sconti già applicati",
	getmore: "Get More",
	extra: "Costi supplementari da pagare al momento della prenotazione",
	roomnetcost: "Costo netto camera",
	reviews: "recensioni",
	arrival: "Arrivo",
	leaving: "Partenza",
	nights: "Notti",
	rooms: "Camere",
	persons: "Persone",
	onsitecostsexcluded: "esclusi eventuali costi in loco",
	onsitecoststotal: "Totale costi in loco",
	go: "ANDATA",
	return: "RITORNO",
	route: "Tratta",
	passengers: "Passeggeri",
	accommodation: "Sistemazione",
	transportation: "Mezzo di trasporto",
	plate: "Targa",
	company: "Compagnia",
	transferroute: "Trasferimento",
	vehicle: "Veicolo",
	flight: "Volo",
	otherdiscounts: "Altri sconti",
	packageextras: "Extra pacchetto",
	axa: "AXA Polizza 360°",
	cancellationinsurance: "Annullamento, Spese mediche, Bagagli",
	insuredpersons: "Persone Assicurate",
	readconditions: "Vedi condizioni Assicurazione",
	packages: "Pacchetti",
	companydata: "Dati Aziendali",
	custmerdata: "Dati Cliente Pagante",
	travelerdata: "Dati Viaggiatore",
	passengersdata: "Dati Passeggeri",
	passenger: "Passeggero",
	privacyconfirmation:
		"Confermo di aver letto e di accettare Privacy & Cookies.",
	newsletterconfirmation:
		"Voglio ricevere informazioni e offerte via email da Charming.",
	updatedata: "Aggiorna i tuoi dati",
	customernotes: "NOTE DEL CLIENTE",
	structurenotes: "NOTE (Struttura per il cliente)",
	bookingnotes: "NOTE (Booking per il cliente)",
	vouchernotes: "NOTE VOUCHER",
	packagenotes: "NOTE PACCHETTO",
	travelinsurance: "Assicurazione Viaggio",
	details: "dettagli",
	ship: "Nave",
	costssummary: "Riepilogo costi e pagamenti",
	totalpract: "Totale pratica",
	holidaystart: "Inizio Vacanza",
	holidaystop: "Fine Vacanza",
	location: "Località",
	printfile: "Stampa la tua pratica",
	rating: "Valutazione",
	services: "Servizi",
	cleaning: "Pulizia",
	staff: "Staff",
	comfort: "Comfort",
	qualityprice: "Rapporto qualità prezzo",
	catering: "Ristorazione",
	ratestructure: "Valuta la struttura",
	leavecommentstructure: "Lascia un giudizio sulla struttura",
	positivecomment: "Giudizio Positivo",
	negativecomment: "Giudizio Negativo",
	rateourservice: "Valuta il nostro servizio",
	howwasreservewithus: "Come è stato prenotare con noi?",
	leavecomment: "Lascia un commento",
	ratelocation: "Valuta la località",
	none: "Nessuno",
	youngcouples: "Coppie giovani",
	maturecouples: "Coppie mature",
	familywithyoungsons: "Famiglia con figli piccoli",
	familywithbigsons: "Famiglia con figli grandi",
	withfriends: "Con gli amici",
	singletravellers: "Viaggiatore singolo",
	groupstravel: "Viaggio di gruppo",
	save: "Risparmi {savedAmount}!",
	costofyourholiday:
		"Il costo della tua vacanza è di {totalAmount} invece che {undiscountedAmount}",
	writelike: "Scrivi cosa ti è piaciuto",
	writedislike: "Scrivi cosa non ti è piaciuto",
	startingfrom: "a partire da",
	pho: "Tel.",
	atglance: "A colpo d'occhio",
	charmingopinion: "Opinione Charming",
	days: "Giorni",
	hours: "Ore",
	minutes: "Minuti",
	seconds: "Secondi",
	paycreditcard: "Paga con Carta di Credito",
	paythreetrances: "Paga in tre rate da {amount}",
	paybanktransfer: "Paga con Bonifico Bancario",
	pay: "Paga",
	howtodeposit: "Come fare a pagare con bonifico bancario o postale",
	close: "Chiudi",
	voucher: "Voucher",
	novoucheravailable:
		"Il documento di viaggio relativo a questo servizio non è ancora disponibile.",
	yes: "Si",
	no: "No",
	toTop: "Torna su",
	topayuntil: "Da pagare entro",
	paidon: "Pagato il",
	costtransf: "Costo del transfer",
	costtottransfdiscounts: "Sconto applicato",
	costtottransf: "Costo totale transfer",
	at: "alle ore",
	validations: {
		required: "Il campo è obbligatorio.",
		maxLength: "Il campo deve avere una lunghezza massima di {max} caratteri.",
		numeric: "Il campo deve essere un numero.",
		email: "Il campo deve essere un indirizzo email valido.",
		taxCode: "Il campo deve essere un codice fiscale valido."
	},
	roomcost: "COSTO CAMERA",
	requiredfield: "Campo obbligatorio",
	arrivaldate: "Data di arrivo",
	departuredate: "Data di partenza",
	adultslabel: "Adulti",
	childslabel: "Bambini",
	regions: "Regioni",
	cities: "Città",
	noresults: "Nessun risultato",
	search: "Cerca",
	edit: "Modifica",
	experiencesfound: "esperienze trovate",
	experiences: "Esperienze",
	addExperience: "Aggiungi Esperienza",
	discoveractivities: "Scopri le attività selezionate per te dal nostro Team.",
	filters: "Filtri",
	appliedfilters: "Filtri applicati",
	closefilters: "Chiudi filtri",
	filter: "Filtra",
	changesearch: "Modifica la ricerca",
	showmoreexp: "Mostra altre esperienze",
	showmoretravel: "Mostra altri viaggi smart",
	showtravel: "Mostra viaggi",
	update: "Aggiorna",
	chooseyourinterests: "Scegli i tuoi interessi",
	applyfilters: "Applica i filtri",
	language: "Lingua",
	selectLanguage: "Seleziona una lingua",
	whatdestination: "Quale destinazione vuoi visitare?",
	whentravel: "Quando andrai in vacanza?",
	withtravel: "Con chi partirai?",
	whatkindoftraveler: "Che tipo di viaggiatore sei?",
	duration: "Durata",
	perperson: "Per persona",
	howumuchtospend: "Quanto vuoi spendere?",
	uniquejourney: "Suggeriti per te dai Local Expert",
	clear: "Pulisci",
	allitaly: "Tutta l'Italia",
	experiencescart: "Carrello Esperienze",
	selectyourinterests: "Seleziona i tuoi interessi",
	thankspurchase: "Grazie per il tuo acquisto",
	emailwithrecap:
		"A breve riceverai un’email di conferma con il riepilogo delle esperienze acquistate. Buon divertimento!",
	continuenavigation: "Continua la navigazione del sito",
	thanksforusing: "Grazie per aver usato",
	highlights: "In evidenza",
	evidence: "In evidenza",
	highlightLabel: "Highlights",
	description: "Descrizione",
	importantinformation: "Informazioni importanti",
	includesnotincludes: "Include/non include",
	meetingpoint: "Punto d'incontro",
	cancellationpolicy: "Politiche di cancellazione",
	temesofthisexperience: "I temi di questa esperienza",
	bookingdata: "DATI PER LA PRENOTAZIONE",
	verifytheavailability: "Verifica la disponibilità",
	selecttravel: "Scegli questo viaggio",
	dataaddon: "Dati aggiuntivi",
	weneedmoreinformation: "Per prenotare questa esperienza abbiamo bisogno di alcune ulteriori informazioni.",
	choosethetipology: "Scegli la tipologia",
	bringyourjourneytolife: "Dai vita al tuo viaggio",
	theteamof: "Il team di",
	bestoriceguaranteed: "Miglior Prezzo Garantito",
	advicefromlocalexperts: "Consulenza da Esperti Locali",
	assistance77: "Assistenza 7 giorni su 7",
	safepayment: "Pagamento Sicuro",
	ticketandextra: "Ticket ed Extra",
	ticket: "Biglietto",
	selectTicket: "Seleziona il numero di biglietti",
	remove: "Rimuovi",
	experiencescart: "Carrello Esperienze",
	total: "Totale",
	confirm: "Conferma",
	voucheraccepted: "Voucher sul cellulare accettato",
	immediateconfirmation: "Conferma immediata",
	freecancellation: "Cancellazione gratuita",
	upto24hours: "Fino a 24 ore prima",
	continuepurchase: "Continua gli acquisti",
	noexperiencesoncart: "Ancora nessuna esperienza nel carrello",
	warning: "Attenzione",
	youhave: "Hai a disposizione",
	minutestoendthebooking:
		"minuti per concludere la prenotazione. Assicurati di avere tutte le informazioni necessarie.",
	timeended: "Tempo scaduto!",
	verifyifjourneyavailable: "Verifica se il viaggio è ancora disponibile.",
	travelerdata: "Dati del viaggiatore",
	discountcode: "Codice Sconto",
	optional: "Facoltativo",
	insertdiscountcode: "Inserisci il codice sconto",
	apply: "Applica",
	generalconditionsandprivacy: "Condizioni generali e privacy",
	generalconditionsandcancellation:
		"Condizioni Generali e Politiche di Cancellazione",
	generalconditions: "Condizioni Generali",
	cancellationpolicy: "Politiche di cancellazione",
	privacypolicy: "Condizioni Privacy Policy",
	within: "entro",
	atthetimeofconfirmation: "al momento della conferma",
	infoonpayment: "Informazioni sul pagamento",
	deposittobepaid: "Caparra da pagare",
	balancetobepaidby: "Saldo da pagare entro",
	bookwithbanktransfer: "Prenota con bonifico bancario",
	bookwithcreditcard: "Prenota subito con carta di credito",
	paytheadvancepayment: "Paga l'acconto in 3 rate da",
	withoutinterest: "senza interessi",
	errorinbooking: "Errore nella prenotazione",
	choodethemethod: "Scegli il metodo più adatto a te",
	bookandpay: "Prenota e paga",
	booktravel: "Prenota il viaggio",
	agencyrightsalreadyincluded: "Diritti d’agenzia già inclusi nel prezzo",
	experiencesrecap: "Riepilogo esperienze",
	insertrequestinfo:
		"Inserisci i dati richiesti per procedere con la prenotazione.",
	acceptgeneralconditions:
		"Accetta le condizioni generali e la privacy policy per procedere.",
	confirmbooking: "Conferma la prenotazione",
	experiencesnotfound: "Non sono state trovate esperienze",
	filtersearch: "Filtra la ricerca",
	searchadestination: "Cerca una destinazione",
	noresults: "Nessun risultato",
	readless: "Leggi meno",
	sharewithfriends: "Condividi questa esperienza con amici e familiari",
	languages: "Lingue",
	groupsize: "Dimensione del gruppo",
	immediateconfirmation: "Conferma immediata",
	animalsaccepted: "Animali ammessi",
	experienceforanyone: "Esperienza per tutti",
	cancellationterms: "Termini di cancellazione",
	fullpricefrom: "Intero da",
	reducedby: "Ridotto da",
	share: "Condividi",
	discovermore: "Scopri di più",
	discovermorehotel: "Scopri di più sull'hotel",
	discoverlesshotel: "Chiudi info sull'hotel",
	searchexperiences: "Cerca esperienze",
	option: "OPZIONE",
	from: "Da",
	a: "a",
	locality: "Località",
	viewonthemap: "Vedi sulla mappa",
	view: "Vedi",
	adult: "Adulto",
	children: "Bambini",
	child_age: "Età bambino",
	close: "Chiudi",
	travelers: "Viaggiatori",
	adults: "Adulti",
	and: "e",
	child: "Bambino",
	year: "Anno",
	years: "Anni",
	of: "di",
	chooseyourjourney: "Scegli il tuo viaggio",
	arrivaldate: "Data arrivo",
	whendoyouwanttoleave: "Quando vuoi partire?",
	searchexperiencesorlocality: "Cerca esperienze o località",
	experiencesnotavailable:
		"Nella località richiesta non sono disponibili esperienze. Dai un'occhiata a queste a pochi km da",
	accommodationsNotAvailable: "Nella località richiesta non sono disponibili alloggi. Dai un’occhiata a queste, a pochi km da",
	smartjourneysnotfound:
		"Siamo spiacenti, non abbiamo ancora dei viaggi corrispondenti alla tua ricerca.",
	filterbylocalityorjourneyname: "Filtra per località o nome del viaggio",
	viaggismartfound: "viaggi smart trovati",
	smarttrips: "Viaggi Smart",
	travelsolutions:
		"Proposte di Viaggio complete create per te dai nostri Local Expert.",
	personalizationavailable:
		"Puoi personalizzarle scegliendo l’alloggio e le esperienze che preferisci.",
	travelduration: "Quanto dura il tuo viaggio?",
	durationnights: "Durata in notti (campo libero)",
	night: "notte",
	reset: "Resetta",
	expand: "Espandi",
	otherservices: "Altri Servizi",
	fromto: "Data Da/A",
	refund: "Rimborso",
	hotelincluded: "Hotel incluso",
	experiencesincluded: "Esperienze incluse",
	duration: "Durata",
	from: "Da",
	to: "A",
	days: "Giorni",
	day: "Giorno",
	hours: "Ore",
	hour: "Ora",
	minutes: "Minuti",
	minute: "Minuto",
	thethemes: "I temi",
	nottobemissed: "Da non perdere",
	poi: "Punti di interesse",
	months: "Gennaio,Febbraio,Marzo,Aprile,Maggio,Giugno,Luglio,Agosto,Settembre,Ottobre,Novembre,Dicembre",
	targets: "famiglia,coppie,single,amici,anziani",
	localexpertsopinion: "Opinione dei local expert",
	tovisitifyouhavetime: "Da visitare, se ne hai il tempo e sei nei dintorni.",
	itdeservesavisit: "Potrebbe non essere vicino, ma merita una deviazione",
	donotmiss: "Da solo vale il viaggio!",
	week: "Settimana",
	who: "Chi",
	when: "Quando",
	travelersdata: "Dati di tutti i viaggiatori",
	inserttravelersdata: "Inserisci i dati di tutti i viaggiatori",
	traveler: "Viaggiatore",
	add: "Aggiungi",
	date: "Data",
	experience: "Esperienza",
	seeaccommodation: "vedi alloggio",
	select: "Seleziona",
	chooseRoom: "Scegli la camera",
	timetable: "Orario",
	chooseTime: "Scegli l'orario",
	chooseLanguage: "Scegli la lingua",
	ticketType: "Tipologia biglietto",
	changehotel: "Cambia alloggio",
	therearenoexperiencesinserted: "Non ci sono esperienze inserite",
	loading: "Stiamo recuperando i dati per te...",
	AddContinue: "Aggiungi e prosegui",
	allOfItaly: "Tutta l'Italia",
	accommodationNotIncluded: "La struttura inclusa in questo viaggio non è disponibile nelle date selezionate",
	chooseAnotherAccommodation: "Scegli un'altra struttura",
	nocancellationterms: "Nessuna politica di cancellazione specificata",
	experienceIsNotAvailable: "L'esperienza selezionata non è disponibile nelle date selezionate.",
	addAnotherExperience: "Cambia la data o aggiungi un'altra esperienza",
	itinerarySummary: "Riepilogo itinerario",
	toBePaidImmediately: "Da pagare subito",
	toBePaidBy: "Da pagare entro",
	enterDetails: "Inserisci i tuoi dati",
	editDetails: "Modifica i tuoi dati",
	thecodeinsertedcouldnotbeapplied: "Il codice sconto inserito non può essere applicato. Ti preghiamo di inserire un codice valido",
	verifythecode: "Verifica il codice",
	discountcodecorrectlyappliedpriceupdated: "Codice sconto correttamente applicato, il prezzo è stato aggiornato",
	thediscountcodehasbeenappliedcorrectly: "Il codice sconto è stato applicato correttamente",
	chooseRoom: "Seleziona la tua camera",
	priceRoom: "Prezzi a notte, a camera",
	terms: "Condizioni",
	warningnoavailability: "Attenzione",
	oneofthechosenproductsisnologneravailable: "Uno dei prodotti scelti non è più disponibile. Verifica il tuo itinerario.",
	chooseTravel: "Scegli il viaggio",
	personalize: "Personalizza",
};
