import { Params } from "@/types/params";
import { useSessionStorage } from "@vueuse/core";
import { defineStore } from "pinia";

const params = useSessionStorage("kb-par", {});

export const useParamsStore = defineStore("params", () => {
	const setParams = async (data: Params) => {
		params.value = data;
	};

	return { params, setParams };
});
